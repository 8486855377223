import { graphql, useStaticQuery } from "gatsby";

const GlobalHeaderQuery = () => {
  const { sanityHeader } = useStaticQuery(graphql`
    query SiteGlobalHeaderQuery {
      sanityHeader(_id: { eq: "header" }) {
        _rawHeaderMenuLeft(resolveReferences: { maxDepth: 15 })
        _rawHeaderMenuRight(resolveReferences: { maxDepth: 15 })
        _rawHeaderImage(resolveReferences: { maxDepth: 15 })
        _rawHeaderLogo(resolveReferences: { maxDepth: 15 })
        _rawExcerpt(resolveReferences: { maxDepth: 15 })
      }
    }
  `);
  return sanityHeader;
};
export default GlobalHeaderQuery;