import React, { Component, useState, useEffect } from "react";
import { globalHistory } from "@reach/router";
import GlobalHeaderQuery from "../static-queries/globalHeaderQuery";
import { PageLink } from "./link";
import { Link } from "gatsby";
import { SanityImage } from "./sanityImage.js";
import PortableText from "./portableText.js";
import cx from "classnames";
import Header_Logo from "../icons/header_logo";
import Logo_Gold from "../icons/logo_gold";
import Hamburger from "../icons/hamburger";
import C_Logo_Inverted from "../icons/c_logo-inverted";
import Shop_Icon from "../icons/shop_icon";
import CloseNav from "../icons/close_mobile-nav";
import header from "../../../studio/schemas/documents/header";
import ThemeContext from "../context/ThemeContext";

const Header = (props) => {
  const [onBlog, setOnBlog] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [onHome, setOnHome] = useState(false);
  const headerData = GlobalHeaderQuery();

  const hoverNavItem = (e) => {
    let targetEl = e.target;
    let parentEl;
    if (targetEl.classList.contains(".headerNavLink")) {
      parentEl = targetEl;
    } else {
      parentEl = targetEl.closest(".headerNavLink ");
    }
    let navBorder = parentEl.querySelector(".headerNavLinkBorder");
    navBorder.style.opacity = "1";
  };

  const exitNavItem = (e) => {
    let targetEl = e.target;
    let parentEl;
    if (targetEl.classList.contains(".headerNavLink")) {
      parentEl = targetEl;
    } else {
      parentEl = targetEl.closest(".headerNavLink ");
    }
    let navBorder = parentEl.querySelector(".headerNavLinkBorder");
    navBorder.style.opacity = "0";
  };

  const mobileNavTrigger = () => {
    document.body.style.position = "fixed";
    document.body.style.overflow = "hidden";
    setMobileNavOpen(true);
  };

  const mobileNavClose = () => {
    document.body.style.position = "relative";
    document.body.style.overflow = "auto";
    setMobileNavOpen(false);
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    let pathArray = window.location.pathname.split("/");
    let handle = pathArray[1];

    if (handle === "") {
      setOnHome(true);
    }

    globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        if (action === "PUSH") {
          pathArray = window.location.pathname.split("/");
          handle = pathArray[1];
          if (
            handle === "journal" &&
            (pathArray[2] == undefined || pathArray[2] === "")
          ) {
            setOnBlog(true);
            setOnHome(false);
          } else if (handle === "journal" && isNaN(pathArray[2]) == false) {
            setOnBlog(true);
            setOnHome(false);
          } else if (handle === "") {
            setOnHome(true);
            setOnBlog(false);
          } else {
            setOnBlog(false);
            setOnHome(false);
          }
        }
      }
    });

    window.addEventListener("scroll", handleScroll, { passive: true });

    window.addEventListener("popstate", () => {
      pathArray = window.location.pathname.split("/");
      handle = pathArray[1];

      if (
        handle === "journal" &&
        (pathArray[2] === "" || pathArray[2] === undefined)
      ) {
        setOnBlog(true);
        setOnHome(false);
      } else if (handle === "journal" && isNaN(pathArray[2]) == false) {
        setOnBlog(true);
        setOnHome(false);
      } else if (handle === "") {
        setOnHome(true);
        setOnBlog(false);
      } else {
        setOnBlog(false);
        setOnHome(false);
      }
    });

    // pathArray = window.location.pathname.split('/');
    // handle = pathArray[1];
    // if(handle === "journal" && (pathArray[2] === "" || pathArray[2] === undefined)) {
    //   setOnBlog(true)
    // } else if (handle === "journal" && (isNaN(pathArray[2])) == false){
    //   setOnBlog(true)
    // } else {
    //   setOnBlog(false)
    // }
    setPageLoaded(true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (pageLoaded) {
    if (scrollPosition !== null) {
      return (
        <ThemeContext.Consumer>
          {(theme) => (
            <header className="header_container fixed top-0 header flex flex-col justify-between items-center w-full bg-none z-50">
              <div
                style={
                  mobileNavOpen
                    ? { transform: "translateX(0%)" }
                    : { transform: "translateX(-100%)" }
                }
                className={cx("mobileNavOverlay bg-offWhite")}
              >
                <div className="mobileNavHeader w-full px-5">
                  <div className="flex justify-between items-center w-full h-full border-b border-dashed border-gold">
                    <div onClick={mobileNavClose}>
                      <CloseNav />
                    </div>
                    <div onClick={mobileNavClose}>
                      <Link to="/" _type="internalLink">
                        <C_Logo_Inverted className="mobileCLogo" />
                      </Link>
                    </div>
                    <Shop_Icon />
                  </div>
                </div>
                {headerData._rawHeaderMenuLeft.items.map((item) => (
                  <PageLink
                    onClick={mobileNavClose}
                    className="mobileNavLink"
                    {...item}
                    key={item._key}
                    _type="internalLink"
                  >
                    {item.title}
                  </PageLink>
                ))}
                <PageLink
                  onClick={mobileNavClose}
                  key={4}
                  link="/storelocater"
                  _type="internalLink"
                  className="mobileNavLink cursor-pointer"
                >
                  Find Us
                </PageLink>
                <PageLink
                  onClick={mobileNavClose}
                  key={5}
                  link="/shop"
                  _type="internalLink"
                  className="mobileNavLink cursor-pointer"
                >
                  Order Tequila
                </PageLink>
              </div>

              <div
                className={cx(
                  onHome === false && scrollPosition > 50
                    ? "bg-opacity-1"
                    : "bg-opacity-0",
                  "md:hidden mobile_nav relative flex justify-between items-center z-20 w-full px-4 bg-offWhite transition-bg-opacity duration-200 ease-in-out z-30"
                )}
              >
                <div
                  onClick={mobileNavTrigger}
                  className="flex justify-start w-1/3"
                >
                  <Hamburger />
                </div>
                <div
                  className={cx(
                    onHome === true ? "hidden" : "flex",
                    "flex justify-center w-1/3"
                  )}
                >
                  <Link to="/" _type="internalLink">
                    <C_Logo_Inverted className="mobileCLogo" />
                  </Link>
                </div>
                <div
                  className="flex justify-end w-1/3"
                  onClick={() => {
                    theme.setCartStatus(true);
                  }}
                >
                  <Shop_Icon />
                </div>
                {onHome === false && (
                  <div
                    className={cx(
                      scrollPosition > 50 ? "px-0" : "px-4",
                      "absolute bottom-0 left-0 flex w-full"
                    )}
                  >
                    <div className="w-full h-1 border-b border-dashed border-gold" />
                  </div>
                )}
              </div>

              <div
                className={cx(
                  onHome === false && scrollPosition > 120
                    ? "bg-opacity-1"
                    : "bg-opacity-0",
                  "hidden md:flex justify-between items-center w-full h-full px-8 bg-offWhite transition-bg-opacity duration-200 ease-in-out z-30"
                )}
              >
                <nav className="flex flex-row justify-start w-1/3 h-full">
                  {headerData._rawHeaderMenuLeft.items.map((item) => (
                    <PageLink
                      onMouseEnter={hoverNavItem}
                      onMouseLeave={exitNavItem}
                      className="headerNavLink flex flex-col justify-center mr-7 text-gold text-sm font-sans lowercase pb-2.5"
                      {...item}
                      key={item._key}
                      _type="internalLink"
                    >
                      <div>{item.title}</div>
                      <div className="headerNavLinkBorder opacity-0" />
                    </PageLink>
                  ))}
                </nav>
                <div
                  className={cx(
                    onHome === true ? "hidden" : "flex",
                    "flex justify-center items-center w-1/3 h-full"
                  )}
                >
                  <Link to="/" _type="internalLink">
                    <C_Logo_Inverted />
                  </Link>
                </div>
                <div className="flex justify-end w-1/3 h-full">
                  <nav className="flex flex-row">
                    {headerData._rawHeaderMenuRight.items.map((item) => (
                      <PageLink
                        onMouseEnter={hoverNavItem}
                        onMouseLeave={exitNavItem}
                        className="headerNavLink flex flex-col justify-center ml-7 text-gold text-sm font-sans lowercase pb-2.5"
                        {...item}
                        key={item._key}
                        _type="internalLink"
                      >
                        <div>
                          <span className="border-2 border-gold pb-3 pr-4 pl-4 hover:bg-gold hover:text-white">
                            {item.title}
                          </span>
                        </div>
                        <div className="headerNavLinkBorder opacity-0 hidden" />
                      </PageLink>
                    ))}
                  </nav>
                  <div
                    onMouseEnter={hoverNavItem}
                    onMouseLeave={exitNavItem}
                    onClick={() => {
                      theme.setCartStatus(true);
                    }}
                    className="headerNavLink flex flex-col justify-center ml-7 text-gold text-sm font-sans lowercase pb-2.5 cursor-pointer"
                  >
                    <div>Cart</div>
                    <div className="headerNavLinkBorder opacity-0" />
                  </div>
                </div>
                {onHome == false && (
                  <div
                    className={cx(
                      scrollPosition > 120 ? "px-0" : "px-8",
                      "absolute bottom-0 left-0 flex w-full"
                    )}
                  >
                    <div className="w-full h-1 border-b border-dashed border-gold" />
                  </div>
                )}
              </div>
              <div className="flex justify-center text-center">
                {/* {headerData._rawExcerpt} */}
              </div>
              {onHome == false && (
                <div className="hidden header__divider border-b border-dashed border-gold" />
              )}
            </header>
          )}
        </ThemeContext.Consumer>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default Header;
