import React from "react";

const Mx_Logo = ({ className }) => (

    <svg className={className}  viewBox="0 0 81 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    
    <path d="M0 24.1835L40.9412 -0.0273438L41.2345 0.100998L80.7365 23.8351L41.1337 48.0093L40.8312 47.881L0 24.1835ZM40.9503 2.18198L3.91446 24.1835L41.1245 45.8733L76.822 23.8718L40.9503 2.18198Z" fill="#C8A677"/>
    <path d="M49.8801 29.381V29.216L51.1727 27.8134L49.5867 25.8883L47.9458 27.8317L49.2384 29.216V29.3902H43.1971V29.2252L45.0856 27.8409L48.2391 24.2565L44.9389 20.2596L43.1055 18.8753V18.6828H50.8335V18.8661L49.5409 20.2687L51.0627 22.1022L52.777 20.2687L51.5027 18.8386V18.6553H57.544V18.8386L55.6555 20.2412L52.4194 23.6881L55.8113 27.7951L57.6448 29.1793V29.3535L49.8801 29.381Z" fill="#C8A677"/>
    <path d="M33.4788 29.4362V29.3078L34.8722 27.9236V21.8548L31.957 29.4912H30.1236L27.1808 21.8823V27.9603L28.5835 29.3078V29.482H22.5697V29.3078L24.1556 27.9236V20.3239L22.5605 18.958V18.7563H30.0961L31.7095 23.1291L33.1671 18.7104H40.8035V18.9121L39.2175 20.2964V27.8869L40.8218 29.262V29.4362H33.4788Z" fill="#C8A677"/>
    <defs>
    <clipPath id="clip0">
    <rect width="80.7364" height="48" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
);

export default Mx_Logo;