import React, { useState, useEffect } from "react";
import cx from "classnames";
import GlobalFooterQuery from "../static-queries/globalFooterQuery";
import { PageLink } from "./link";
import addToMailchimp from "gatsby-plugin-mailchimp";
import C_Logo from "../icons/c_logo";
import Mx_Logo from "../icons/mx_logo";
import Footer_Logo from "../icons/footer_logo";
import Logo from "../icons/logo";
import Arrow from "../icons/arrow";
import { Helmet } from "react-helmet";

const Footer = () => {
  const [matches, setMatches] = useState(false);
  const { _rawFooterBottomLinks, _rawFooterLinkLists } = GlobalFooterQuery();
  const [mcResponse, setMcResponse] = useState({ email: "", result: null });
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    const media = window.matchMedia("(min-width: 768px)");
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const resizeListener = () => setMatches(media.matches);

    // check on resize
    window.addEventListener("resize", resizeListener);

    // check once on initial page load
    resizeListener();

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  });

  const hoverNavItem = (e) => {
    let targetEl = e.target;
    let parentEl;
    if (targetEl.classList.contains(".navLinkContainer")) {
      parentEl = targetEl;
    } else {
      parentEl = targetEl.closest(".navLinkContainer");
    }

    let navArrow = parentEl.querySelector(".navArrow");
    navArrow.style.opacity = "1";
  };

  const exitNavItem = (e) => {
    let targetEl = e.target;
    let parentEl;
    if (targetEl.classList.contains(".navLinkContainer")) {
      parentEl = targetEl;
    } else {
      parentEl = targetEl.closest(".navLinkContainer");
    }

    let navArrow = parentEl.querySelector(".navArrow");
    navArrow.style.opacity = "0";
  };

  const handleChange = (event) => {
    let emailInput = event.target.value;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(emailInput)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  };

  const handleNewsletterSubmit = async (event) => {
    setMcResponse({});
    event.preventDefault();
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const formData = new FormData(event.target);

    let emailError = event.target.querySelector(".form__error");
    let emailErrorSubscribed = event.target.querySelector(
      ".form__error-subscribed"
    );
    let emailInput = event.target.querySelector(".newsletter__input");

    if (!emailRegex.test(emailInput.value)) {
      emailError.style.display = "flex";
      setTimeout(function () {
        emailError.style.display = "none";
      }, 3000);
      return;
    } else {
      let response = await addToMailchimp(formData.get("email"));
      setMcResponse(response);
      updateForms(emailInput, emailError, emailErrorSubscribed, response);
    }
  };

  const updateForms = (
    emailInput,
    emailError,
    emailErrorSubscribed,
    response
  ) => {
    // SUCCESS STATE
    if (response.result === "success") {
      emailInput.value = "You're on the list!";
      setTimeout(() => {
        emailInput.value = "";
      }, 3000);

      // ERROR STATE
    } else if (response.result === "error") {
      let responseMessage = String(response.msg);
      let alreadySubscribed = "already subscribed";
      if (responseMessage.includes(alreadySubscribed)) {
        // IF ON DESKTOP
        if (matches) {
          emailErrorSubscribed.style.display = "flex";
          setTimeout(function () {
            emailErrorSubscribed.style.display = "none";
          }, 3000);

          // IF ON MOBILE
        } else {
          emailInput.value = "You're already subscribed!";
          setTimeout(() => {
            emailInput.value = "";
          }, 3000);
        }
      } else {
        if (matches) {
          emailError.style.display = "flex";
          setTimeout(function () {
            emailError.style.display = "none";
          }, 3000);
        } else {
          emailInput.value = "Submission Error";
          setTimeout(() => {
            emailInput.value = "";
          }, 3000);
        }
      }
    }
  };

  return (
    <footer
      className={
        "footer container flex flex-col items-center bg-offWhite text-darkGray"
      }
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex flex-col items-center w-full">
          {/* <C_Logo className="c_logo" /> */}
          <Footer_Logo className="footer_logo" />
          <div className="flex flex-col md:grid grid-cols-2 md:gap-20 w-full pt-20">
            <div className="grid grid-cols-2 md:flex justify-between w-full">
              {_rawFooterLinkLists.map((menu, index) => {
                return index === 0 ? (
                  <div
                    className="col-span-1 first_div w-30 justify-start"
                    key={menu._id}
                  >
                    <p className="footer__list-title font-sans text-gold lowercase text-xs pb-3 mb-4">
                      {menu.title}
                    </p>
                    {menu.items.map((item, index) => (
                      <div
                        onMouseEnter={hoverNavItem}
                        onMouseLeave={exitNavItem}
                        className="navLinkContainer flex items-center"
                        key={item._key}
                      >
                        <PageLink
                          className="font-historicalItalic text-darkGray block text-sm"
                          {...item}
                        >
                          {item.title}
                        </PageLink>
                        <Arrow className="navArrow opacity-0 md:flex z-10 ml-2" />
                      </div>
                    ))}
                  </div>
                ) : index === 1 ? (
                  <div
                    className="col-span-1 first_div w-30 justify-center"
                    key={menu._id}
                  >
                    <p className="footer__list-title font-sans text-gold lowercase text-xs pb-3 mb-4">
                      {menu.title}
                    </p>
                    {menu.items.map((item) => (
                      <div
                        onMouseEnter={hoverNavItem}
                        onMouseLeave={exitNavItem}
                        className="navLinkContainer flex items-center"
                        key={item._key}
                      >
                        <PageLink
                          className="font-historicalItalic text-darkGray block text-sm"
                          {...item}
                        >
                          {item.title}
                        </PageLink>
                        <Arrow className="navArrow opacity-0 md:flex z-10 ml-2" />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="col-span-2 md:col-span-1 flex flex-col first_div w-30 pb-10 md:pb-0"
                    key={menu._id}
                  >
                    <p className="footer__list-title font-sans text-gold lowercase text-xs pb-3 mb-4 pt-10 md:pt-0">
                      {menu.title}
                    </p>
                    <div className="grid grid-cols-2 md:flex flex-col">
                      {menu.items.map((item, index) => (
                        <div
                          onMouseEnter={hoverNavItem}
                          onMouseLeave={exitNavItem}
                          className="navLinkContainer flex items-center"
                          key={item._key}
                        >
                          <PageLink
                            className="col-span-1 font-historicalItalic text-darkGray block text-sm"
                            {...item}
                          >
                            {item.title}
                          </PageLink>
                          <Arrow className="navArrow opacity-0 md:flex z-10 ml-2" />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-full mailchimp_container">
              <h6 className="footer__list-title text-gold lowercase pb-3">
                Signup for updates
              </h6>

              <div className="w-full block">
                <div className="klaviyo-form-UbYQLF"></div>
              </div>

              <div className="text-2xs md:mt-2 mb-4 text-center">
                You agree to the{" "}
                <PageLink link="/privacy-policy" className="text-gold text-2xs">
                  Privacy Policy
                </PageLink>
              </div>

              {/* <div dangerouslySetInnerHTML={{ __html: mcResponse.msg }}/> */}
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full pb-16 mt-10 md:mt-0">
          <div className="flex justify-start w-1/5 md:w-1/3">
            <Mx_Logo className="mx_logo" />
          </div>
          <div className="flex flex-col items-center justify-center w-3/5 md:w-1/3">
            <a
              href="https://www.speakeasyco.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="block text-gold text-2xs font-serif lowercase"
            >
            </a>
            <PageLink
              link="/privacy-policy"
              className="block text-gold text-2xs font-serif lowercase"
            >
              Privacy Policy
            </PageLink>
            <PageLink
              link="/terms"
              className="block text-gold text-2xs font-serif lowercase"
            >
              Terms &amp; Conditions
            </PageLink>
            <PageLink
              link="/cookie-policy"
              className="block text-gold text-2xs font-serif lowercase"
            >
              Cookie Policy
            </PageLink>
        <div className="flex justify-center w-full mt-2">
          <span className="block text-red-500 text-xs font-serif lowercase mt-2">
            The Jose Celaya LLC, Manhattan Beach, CA
          </span>
        </div>
            <a
              href="https://www.speakeasyco.com/shipping-times"
              target="_blank"
              rel="noopener noreferrer"
              className="block text-gold text-2xs font-serif lowercase text-center"
            >
            </a>
          </div>
          <div className="flex justify-end w-1/5 md:w-1/3">
            <Logo width="61px" height="23px" />
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script>window.addEventListener('load', function() {
 (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#404040', triggerColor : '#404040', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'center', triggerOffsetX : 10, triggerOffsetY : 0, triggerRadius : '50%' } }); }; h.appendChild(s); })(); }); </script>`,
        }}
      />
    </footer>
  );
};

export default Footer;
