import React, { Component, useState, useLayoutEffect } from "react";
import { globalHistory } from "@reach/router";
import GlobalHeaderQuery from "../static-queries/globalHeaderQuery";
import GlobalSplashQuery from "../static-queries/globalSplashQuery";
import PortableText from "./portableText";
import cx from "classnames";

import C_Logo_Dark from "../icons/c_logo-dark";

const Splash = props => {
  const [seenSplash, setSeenSplash] = useState(true);
  const [splashHidden, setSplashHidden] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [splashViewable, setSplashViewable] = useState(true);
  const splashData = GlobalSplashQuery();

  useLayoutEffect(() => {
    if (localStorage.length === 0) {
      localStorage.setItem("firstVisit", true);
    }
    let firstVisit = localStorage.getItem("firstVisit");
    if (firstVisit === "true") {
      document.body.style.position = "fixed";
      setSeenSplash(false);
      setPageLoaded(true);
    } else {
      let over21 = localStorage.getItem("over21");
      if (over21 !== "true") {
        document.body.style.position = "fixed";
        setSeenSplash(false);
        setPageLoaded(true);
      } else {
        setSeenSplash(true);
        setPageLoaded(true);
      }
    }
    localStorage.setItem("firstVisit", false);
    firstVisit = localStorage.getItem("firstVisit");
  }, []);

  const hideSplash = () => {
    localStorage.setItem("over21", true);
    setSplashViewable(false);
    document.body.style.position = "relative";
    setTimeout(() => {
      setSplashHidden(true);
    }, 1000);
  };

  if (!seenSplash) {
    // if (pageLoaded) {
    return (
      <div
        className={cx(
          splashHidden ? "hidden" : "flex",
          "splashContainer absolute top-0 left-0 w-full h-full"
        )}
      >
        <div
          className={cx(
            splashViewable ? "" : "splashBackgroundHidden",
            "splashBackground fixed top-0 left-0 w-full h-full transition-opacity duration-700 ease-in-out"
          )}
        />
        <div
          className={cx(
            splashViewable ? "opacity-100" : "opacity-0",
            "splashCenterContainer flex justify-center items-center w-full h-full bg-transparent transition-opacity duration-700 ease-in-out"
          )}
        >
          <div className="relative">
            <C_Logo_Dark className={cx("splashIcon")} />
            <div className={cx("splashCenter bg-offWhite opacity-1")}>
              <div className={cx("splashOuterBorder")}>
                <div
                  className={cx(
                    "splashInnerBorder flex flex-col justify-center items-center"
                  )}
                >
                  <div
                    className={cx(
                      "splashHeadline font-historical text-darkGray text-center"
                    )}
                  >
                    {splashData.headline}
                  </div>
                  <button
                    onClick={hideSplash}
                    className={cx(
                      "hidden md:flex splashEnter font-serif lowercase bg-darkGray text-offWhite items-center hover:opacity-90 transition-opacity duration-200 ease-in-out hover:cursor-pointer"
                    )}
                  >
                    {splashData.enterSiteTextDesktop}
                  </button>
                  <button
                    onClick={hideSplash}
                    className={cx(
                      "md:hidden splashEnter font-serif lowercase bg-darkGray text-offWhite flex items-center hover:opacity-90 transition-opacity duration-200 ease-in-out hover:cursor-pointer"
                    )}
                  >
                    {splashData.enterSiteTextMobile}
                  </button>
                  <div
                    className={cx(
                      "splashSubheading font-historicalItalic text-center"
                    )}
                  >
                    <PortableText
                      className="splashSubheading"
                      blocks={splashData._rawBottomText}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // } else {
    //   return null
    // }
  } else {
    return null;
  }
};

export default Splash;
