import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";
import Header from "../components/header";
import Splash from "../components/splash";
import { TransitionWrapper } from "../components/transition";
import Cart from "../components/cart";
import { StoreContext } from "../context/store-context";
import favicon from "../../static/images/favicon.png";

const Layout = ({ children, location }) => {
  const { checkout } = React.useContext(StoreContext);

  return (
    <React.Fragment>
      <Helmet title="Celaya Tequila" />
      <Helmet>
        <link rel="shortcut icon" type="image/x-icon" href={favicon} />
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '528012078859723');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
            <img height="1" width="1" src="https://www.facebook.com/tr?id=528012078859723&ev=PageView&noscript=1"/>
          `}
        </noscript>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10892748141"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10892748141');
            `}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LSR2RLD6ZJ"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LSR2RLD6ZJ');
          `}
        </script>
      </Helmet>
      <div>
        <Cart checkout={checkout} />
        <Header />
        <Splash />
        <TransitionWrapper trigger={location.pathname}>
          {children}
        </TransitionWrapper>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
