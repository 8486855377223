import React, { useState } from "react";

const defaultState = {
  cartObject: {},
  cartItems: false,
  cartStatus: false,
  setCartItems: () => {},
  setCartStatus: () => {},
  deleteCartItem: () => {},
  decreaseQuantity: () => {},
  increaseQuantity: () => {},
  cartTotal: () => {},
};

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends React.Component {
  state = {
    cartObject: {},
    cartItems: false,
    cartStatus: false,
  };

  setCartItems = () => {
    let cart = {};

    const checkCart = JSON.parse(localStorage.getItem("cartItems"));
    if (checkCart) {
      cart = checkCart;
    }

    const count = document.getElementById("count").dataset.count;
    if (!document.querySelector("input[name='product']:checked")) {
      document.querySelector(
        "input[name='product'][value='single']"
      ).checked = true;
    }
    const product = document.querySelector("input[name='product']:checked");
    const type = product.value;
    const title = product.dataset.title;
    const price = product.dataset.price;
    const id = product.dataset.id;
    const image = document.querySelector(".image--main[data-index='0']");

    cart[id] = {};
    cart[id]["type"] = {};
    cart[id]["title"] = {};
    cart[id]["image"] = {};
    cart[id]["price"] = {};
    cart[id]["count"] = {};
    cart[id]["total"] = {};
    cart[id]["type"] = type;
    cart[id]["title"] = title;
    cart[id]["image"] = image.getAttribute("src");
    cart[id]["price"] = price;
    cart[id]["count"] = count;
    cart[id]["total"] = count * price;

    this.cartObject = cart;

    cart["total"] = {};
    cart["total"] = this.cartTotal();

    localStorage.setItem("cartItems", JSON.stringify(cart));
    this.cartObject = cart;
    this.setCartStatus(true);
  };

  setCartStatus = state => {
    this.setState({ cartStatus: state });
  };

  deleteCartItem = key => {
    const cart = this.cartObject;
    delete cart[key];

    cart["total"] = this.cartTotal();
    localStorage.setItem("cartItems", JSON.stringify(cart));
    this.cartObject = cart;
    this.setCartStatus(true);
  };

  decreaseQuantity = key => {
    const cart = this.cartObject;
    const count = cart[key]["count"];
    if (count > 1) {
      cart[key]["count"] = parseInt(count) - 1;
    }

    cart[key]["total"] = cart[key]["count"] * cart[key]["price"];

    cart["total"] = this.cartTotal();
    localStorage.setItem("cartItems", JSON.stringify(cart));
    this.cartObject = cart;
    this.setCartStatus(true);
  };

  increaseQuantity = key => {
    const cart = this.cartObject;
    const count = cart[key]["count"];
    cart[key]["count"] = parseInt(count) + 1;

    cart[key]["total"] = cart[key]["count"] * cart[key]["price"];

    cart["total"] = this.cartTotal();
    localStorage.setItem("cartItems", JSON.stringify(cart));
    this.cartObject = cart;
    this.setCartStatus(true);
  };

  cartTotal = () => {
    const cart = this.cartObject;
    let total = 0;

    for (const key in cart) {
      if (key !== "total") {
        total += parseInt(cart[key]["total"]);
      }
    }

    return total;
  };

  componentDidMount() {
    const checkCart = JSON.parse(localStorage.getItem("cartItems"));
    if (checkCart) {
      this.cartObject = checkCart;
    }
    // Getting dark mode value from localStorage!
    /*const lsDark = JSON.parse(localStorage.getItem("dark"));
    if (lsDark) {
      this.setState({ dark: lsDark });
    } else if (supportsDarkMode()) {
      this.setState({ dark: true });
    }*/
  }

  render() {
    const { children } = this.props;
    const { cartItems } = this.state;
    const { cartStatus } = this.state;
    return (
      <ThemeContext.Provider
        value={{
          cartItems,
          cartStatus,
          setCartItems: this.setCartItems,
          setCartStatus: this.setCartStatus,
          deleteCartItem: this.deleteCartItem,
          decreaseQuantity: this.decreaseQuantity,
          increaseQuantity: this.increaseQuantity,
          cartObject: this.cartObject
        }}
      >
        {children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContext;

export { ThemeProvider };
