import React from "react";
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.scss";

import { ThemeProvider } from "./src/context/ThemeContext";
import { StoreProvider } from "./src/context/store-context";

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <StoreProvider>{element}</StoreProvider>
  </ThemeProvider>
);
