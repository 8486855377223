import React from "react";
import BasePortableText from "@sanity/block-content-to-react";
import { SanityImage } from "./sanityImage";
import { sanity } from "../../client-config.js";
import cx from "classnames";
import * as styles from "./portableText.module.scss";

const serializers = {
  types: {
    /* eslint-disable-next-line react/display-name */
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: ({ node }) => (
      <SanityImage
        className={cx(styles.rteImage)}
        alt={node.alt}
        image={node}
        gatsbyImageDataArgs={{ width: "100vw" }}
      />
    ),
    trigger: ({ node }) => (
      <div data-trigger={node.identifier} className={cx(styles.triggerSpan)}>
        <span>{node.text}</span>
        <div className={cx(styles.triggerSpanTexture)} />
      </div>
    )
  }
};

const PortableText = ({ blocks, className }) => (
  <div className={cx(className, styles.rte)}>
    <BasePortableText blocks={blocks} serializers={serializers} {...sanity} />
  </div>
);

export default PortableText;
