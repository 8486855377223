import React from "react";
import { Link } from "gatsby";

export const PageLink = (props) => {
  if (props._type === "externalLink") {
    return (
      <a
        className={props.className}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onMouseOver={props.onMouseOver}
        onFocus={props.onFocus}
        href={props.link}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </a>
    );
  }

  let link = props.link;
  if (props._type === "internalLink" && link) {
    if (link._type === "page" || link._type === "blogIndex") {
      link = `/${link.page.content.slug.current}`;

      if (link === "/home") {
        link = "/";
      }
    } else {
      //console.error("Unknown internal link type: " + link._type);
    }
  } else {
    //console.error("Unknown link type: " + props._type);
  }

  return (
    <Link
      type={props._type}
      className={props.className}
      activeClassName="active"
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseOver={props.onMouseOver}
      onFocus={props.onFocus}
      to={link}
    >
      {props.children}
    </Link>
  );
};
