import React from "react";

const Hamburger = ({ className, height, width }) => (

    <svg className={className} width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1.25" x2="24" y2="1.25" stroke="#C8A677" stroke-width="1.5"/>
        <line y1="9.25" x2="24" y2="9.25" stroke="#C8A677" stroke-width="1.5"/>
        <line y1="17.25" x2="24" y2="17.25" stroke="#C8A677" stroke-width="1.5"/>
    </svg>

);

export default Hamburger;