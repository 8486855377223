import React, { useState } from "react";
import debounce from "lodash.debounce";
import { StoreContext } from "../context/store-context";
import { formatPrice } from "../utils/format-price";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";
import cx from "classnames";
import * as pdp from "../sections/pdp.module.scss";

export function LineItem({ item, key }) {
  const {
    removeLineItem,
    checkout,
    updateLineItem,
    loading
  } = React.useContext(StoreContext);
  const [quantity, setQuantity] = useState(item.quantity);

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src
  };
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300
  );
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  );

  return (
    <div className="px-4 my-4">
      <div className="flex w-full border-2 border-gold p-1">
        <div className="flex w-full border border-gold p-4">
          <div className="w-24 grow-0">
            {image && (
              <GatsbyImage
                key={variantImage.src}
                image={image}
                alt={variantImage.altText ?? item.variant.title}
              />
            )}
          </div>
          <div className="pl-4 grow">
            <div className="flex w-full justify-between items-center">
              <p className="font-historical text-sm">
                {item.title === "Bundle" ? item.variant.title + " " : ""}
                {item.title}
                {item.title === "The Case Deal"
                  ? " - " + item.variant.title
                  : ""}
              </p>
              <svg
                onClick={handleRemove}
                className="w-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"
                  className="fill-gold"
                />
              </svg>
            </div>
            <p className="font-serif text-gold text-xs leading-none">{price}</p>
            <div className="flex w-full justify-between items-end">
              <div className="flex items-center pt-6">
                <div
                  onClick={doDecrement}
                  className={cx(
                    pdp.btn_qty,
                    "font-serif text-gold rounded-sm border-gold border-2 hover:opacity-80 select-none"
                  )}
                >
                  -
                </div>
                <div className="font-serif leading-none px-4">{quantity}</div>
                <div
                  onClick={doIncrement}
                  className={cx(
                    pdp.btn_qty,
                    "font-serif text-gold rounded-sm border-gold border-2 hover:opacity-80 select-none"
                  )}
                >
                  +
                </div>
              </div>
              <div className="font-serif text-sm">{subtotal}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
