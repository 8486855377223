import { graphql, useStaticQuery } from "gatsby";

const GlobalSplashQuery = () => {
  const { sanitySplash } = useStaticQuery(graphql`
    query SiteGlobalSplashQuery {
      sanitySplash(_id: { eq: "splash" }) {
        headline
        enterSiteTextDesktop
        enterSiteTextMobile
        _rawBottomText(resolveReferences: {maxDepth: 15})
      }
    }
  `);
  return sanitySplash;
};

export default GlobalSplashQuery;