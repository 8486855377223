import { graphql, useStaticQuery } from "gatsby";

const GlobalFooterQuery = () => {
  const { sanityFooter } = useStaticQuery(graphql`
    query SiteGlobalFooterQuery {
      sanityFooter(_id: { eq: "footer" }) {
        _rawFooterBottomLinks(resolveReferences: { maxDepth: 15 })
        _rawFooterLinkLists(resolveReferences: { maxDepth: 15 })
      }
    }
  `);
  return sanityFooter;
};
export default GlobalFooterQuery;