import React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { sanity } from "../../client-config.js";
import { GatsbyImage } from "gatsby-plugin-image";

export const SanityImage = ({
  image,
  className,
  alt,
  caption,
  gatsbyImageDataArgs,
}) => (
  <>
    {image && image.asset && image.asset._id ? (
      <GatsbyImage
        className={className}
        alt={alt}
        image={getGatsbyImageData(image.asset._id, gatsbyImageDataArgs, sanity)}
      />
    ) : (
      <img src="none" alt="Error" />
    )}
    {caption && <figcaption className="small-title">{caption}</figcaption>}
  </>
);