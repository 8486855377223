import React from "react";

const Shop_Icon = ({ className, height, width }) => (

<svg className={className} width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="5.75" width="17.5" height="13.5" stroke="#C8A677" strokeWidth="1.5"/>
    <path d="M14 6V1H5V6" stroke="#C8A677" stroke-width="1.5"/>
</svg>

    
);

export default Shop_Icon;