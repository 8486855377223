import React from "react";
import cx from "classnames";
import * as styles from "./cart.module.scss";
import ThemeContext from "../context/ThemeContext";
import { LineItem } from "../components/lineItem";
import { formatPrice } from "../utils/format-price";
import { GatsbyImage } from "gatsby-plugin-image";
import * as pdp from "../sections/pdp.module.scss";
import { PageLink } from "./link";

export default function Cart(checkoutData) {
  const checkout = checkoutData.checkout;

  const emptyCart = checkout.lineItems.length === 0;

  const lineItems = checkout.lineItems;

  const handleCheckout = async () => {
    let json = {
      items: [],
    };

    let num = 0;
    checkout.lineItems.map((item) => {
      const variantId = item.variant.id.split("/");

      var newItems = {
        id: variantId[4],
        quantity: parseInt(item.quantity, 10),
      };
      json.items[num] = newItems;
      num += 1;
    });

    const jsonFeed = btoa(JSON.stringify(json));

    window.open("https://drinkcelaya.myshopify.com/cart/?json=" + jsonFeed);
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div className={cx(styles.cartMount)}>
          <div className={cx(styles.cartMenu)}>
            <div
              className={cx(
                styles.cartHolder,
                theme.cartStatus ? styles.active : ""
              )}
            >
              <div className="pb-40 mb-40">
                <div className="pt-4 pr-4 pl-4 grid grid-cols-3 items-center">
                  <div
                    onClick={() => {
                      theme.setCartStatus(false);
                    }}
                    className="cursor-pointer"
                  >
                    <svg
                      className="w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
                    </svg>
                  </div>
                  <div className="text-center text-sm leading-none">Cart</div>
                </div>
                <div className={cx(styles.cartMain)}>
                  {emptyCart ? (
                    <>
                      <div className="px-4 my-4">
                        <div className="flex w-full border-2 border-gold p-1">
                          <div className="w-full border border-gold p-4 text-center">
                            <p className="font-historical text-sm mb-4">
                              Cart is Empty
                            </p>
                            <PageLink
                              link={"/shop"}
                              onClick={() => {
                                theme.setCartStatus(false);
                              }}
                              className="text-xs bg-black inline-block w-full text-white font-serif lowercase px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out text-center cursor-pointer"
                            >
                              Shop Our Tequila
                            </PageLink>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {checkout.lineItems.map((item) => (
                        <LineItem item={item} key={item.id} />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            {!emptyCart ? (
              <div
                className={cx(
                  styles.cartFooter,
                  theme.cartStatus ? styles.active : ""
                )}
              >
                <div className="p-4">
                  <div className="flex justify-between pb-4 items-end">
                    <div className="text-xl leading-none pb-2">
                      Order Sub-total:
                    </div>
                    <div className="font-serif text-sm">
                      {formatPrice("USD", checkout.subtotalPrice.amount)}
                    </div>
                  </div>

                  <button
                    onClick={handleCheckout}
                    className="text-xs bg-black inline-block w-full text-white font-serif lowercase px-14 py-5 rounded-sm hover:bg-gold transition-all duration-200 ease-in-out text-center cursor-pointer"
                  >
                    View Cart
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            onClick={() => {
              theme.setCartStatus(false);
            }}
            className={cx(styles.cartBg, theme.cartStatus ? styles.active : "")}
          />
        </div>
      )}
    </ThemeContext.Consumer>
  );
}
